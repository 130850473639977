require('./bootstrap');
import Trix from 'trix/dist/trix-core';
export default Trix;

$("#reset-annuaire").on("click", function () {
  $('.checkbox-annuaire > input[type="checkbox"]').prop("checked", false);
  $("#filters").submit();
});
$("#reset-pays").on("click", function () {
  $('.checkbox-pays > input[type="checkbox"]').prop("checked", false);
  $("#filters").submit();
});
$("#reset-rubrique").on("click", function () {
  $('.checkbox-rubrique > input[type="checkbox"]').prop("checked", false);
  $("#filters").submit();
});

var addImage = document.getElementById('formAjax');
if (addImage !== null) {
  addImage.onsubmit = function (event) {
    event.preventDefault();
    var uploadImage = document.getElementById('uploadAjax');
    var codeClient = document.getElementById("codeclient").value;
    // Get the files from the form input
    var files = uploadImage.files;
    var action = "addImage";
    var hebergementCode = codeClient;
    var key = document.getElementById("key").value;
    // Select only the first file from the input array
    var file = files[0];

    // Check the file type
    if (!file.type.match('image/jpeg') || file.name.substring(file.name.length-3,file.name.length)!=="jpg") {
      document.getElementById('addImage').classList.add("d-none");
      document.getElementById('addImage').style.pointerEvents = "none";
      document.getElementById('spinner').classList.remove("d-none");
      setTimeout(startAnimation, 1000)
      function startAnimation() {
        document.getElementById('addImage').classList.remove("d-none");
        document.getElementById('addImage').classList.add("isvisible", "text-danger");
        document.getElementById('addImage').style.cursor = "initial";
        document.getElementById('spinner').classList.add("d-none");
        document.getElementById('addImage').setAttribute("name", "bug-outline");
        document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-danger alert-dismissible fade show popup-notification" role="alert"> Le fichier doit être un jpg !<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
        setTimeout(endAnimation, 1500)
      }
  
      function endAnimation() {
        document.getElementById('addImage').classList.remove("d-none");
        document.getElementById('addImage').classList.remove("isvisible", "text-danger");
        document.getElementById('addImage').removeAttribute('style');
        document.getElementById('addImage').setAttribute("name", "duplicate-outline");
      }
        return;
    }

    let img = new Image()
    img.src = window.URL.createObjectURL(file)
    img.onload = () => {
      if(img.height<=1080){
        // Add the file to the AJAX request
        var formData = new FormData();
        formData.append('fileAjax', file, file.name);
        formData.append('key', key);
        formData.append('action', action);
        formData.append('hebergementCode', hebergementCode);

        // Set up the request
        var xhr = new XMLHttpRequest();

        // Open the connection
        xhr.open('POST', 'https://www.guideweb.com/hebergement/image.php', true);

        // Send the data.
        xhr.send(formData);
        //location.reload();

        // Set up a handler for when the task for the request is complete
        xhr.onload = function () {
          var response = JSON.parse(xhr.response);
          if (xhr.status == 200) {
            document.getElementById('addImage').classList.add("d-none");
            document.getElementById('infoImage').classList.add("d-none");
            document.getElementById('addImage').style.pointerEvents = "none";
            document.getElementById('spinner').classList.remove("d-none");
            setTimeout(startAnimation, 1000)
            function startAnimation(){
              document.getElementById('addImage').classList.remove("d-none");
              document.getElementById('spinner').classList.add("d-none");
              document.getElementById('addImage').classList.add("isvisible", "text-success");
              document.getElementById('addImage').setAttribute("name", "checkmark-done-circle-outline");
              document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-success alert-dismissible fade show popup-notification" role="alert">' + response.message + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
              setTimeout(reload, 1000)
            }
            function reload(){
              location.reload();
            }
          } 
          else{
            document.getElementById('infoImage').classList.add("d-none");
            document.getElementById('addImage').classList.add("d-none");
            document.getElementById('addImage').style.pointerEvents = "none";
            document.getElementById('spinner').classList.remove("d-none");
            setTimeout(startAnimation, 1000)
  
            function startAnimation(){
              document.getElementById('addImage').classList.remove("d-none");
              document.getElementById('addImage').classList.add("isvisible", "text-danger");
              document.getElementById('spinner').classList.add("d-none");
              document.getElementById('addImage').setAttribute("name", "bug-outline");
              document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-danger alert-dismissible fade show popup-notification" role="alert">' + response.code + ' - ' + response.message + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
              setTimeout(endAnimation, 1500)
            }
  
            function endAnimation(){
              document.getElementById('addImage').classList.remove("isvisible", "text-danger");
              document.getElementById('addImage').removeAttribute('style');
              document.getElementById('infoImage').classList.remove("d-none");
              document.getElementById('addImage').setAttribute("name", "duplicate-outline");
            }
          }
        };
  
        // En cas d'erreur au moment du XHR.Open
        xhr.onerror = function () {
          document.getElementById('infoImage').classList.add("d-none");
          document.getElementById('addImage').classList.add("d-none");
          document.getElementById('addImage').style.pointerEvents = "none";
          document.getElementById('spinner').classList.remove("d-none");
          document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-danger alert-dismissible fade show popup-notification" role="alert">Impossible de joindre Guideweb, merci de contacter un administrateur<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
          setTimeout(startAnimation, 1000)
          function startAnimation() {
            document.getElementById('addImage').classList.remove("d-none");
            document.getElementById('addImage').classList.add("isvisible", "text-danger");
            document.getElementById('addImage').style.cursor = "initial";
            document.getElementById('spinner').classList.add("d-none");
            document.getElementById('addImage').setAttribute("name", "bug-outline");
            setTimeout(endAnimation, 1500)
          }
  
          function endAnimation() {
            document.getElementById('addImage').classList.remove("d-none");
            document.getElementById('infoImage').classList.remove("d-none");
            document.getElementById('addImage').classList.remove("isvisible", "text-danger");
            document.getElementById('addImage').removeAttribute('style');
            document.getElementById('addImage').setAttribute("name", "duplicate-outline");
          }
        }
      }
      else{
        document.getElementById('addImage').classList.add("d-none");
        document.getElementById('addImage').style.pointerEvents = "none";
        document.getElementById('spinner').classList.remove("d-none");
        setTimeout(startAnimation, 1000)
        function startAnimation() {
          document.getElementById('addImage').classList.remove("d-none");
          document.getElementById('addImage').classList.add("isvisible", "text-danger");
          document.getElementById('addImage').style.cursor = "initial";
          document.getElementById('spinner').classList.add("d-none");
          document.getElementById('addImage').setAttribute("name", "bug-outline");
          document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-danger alert-dismissible fade show popup-notification" role="alert">L\'image ne doit pas dépasser 1080px de hauteur !<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
          setTimeout(endAnimation, 1500)
        }
        function endAnimation() {
          document.getElementById('addImage').classList.remove("d-none");
          document.getElementById('addImage').classList.remove("isvisible", "text-danger");
          document.getElementById('addImage').removeAttribute('style');
          document.getElementById('addImage').setAttribute("name", "duplicate-outline");
        }
        return; 
      }               
    }
  }
}



var isImage = document.getElementById('client-images');
if (isImage !== null) {
  window.deleteImage = deleteImage;
  function deleteImage(number) {
    var name = number;
    var imageName = name.replace('.jpg', '');
    // Get the files from the form input
    var action = "deleteImage";
    var key = document.getElementById("key").value;

    // Create a FormData object
    var formData = new FormData();

    // Create the AJAX request
    formData.append('key', key);
    formData.append('action', action);
    formData.append('name', name);

    // Set up the request
    var xhr = new XMLHttpRequest();

    // Open the connection
    xhr.open('POST', 'https://www.guideweb.com/hebergement/image.php', true);

    // Set up a handler for when the task for the request is complete
    xhr.onload = function () {
      var response = JSON.parse(xhr.response);
      if (xhr.status == 200) {
        document.getElementById('deleteImage' + imageName).classList.add("d-none");
        document.getElementById('deleteImage' + imageName).style.pointerEvents = "none";
        document.getElementById('openImage' + imageName).classList.add("d-none");
        document.getElementById('spinner' + imageName).classList.remove("d-none");
        document.getElementById('overlay' + imageName).classList.add("overlay-inprogress");
        setTimeout(startAnimation, 1000)

        function startAnimation() {
          document.getElementById('deleteImage' + imageName).classList.remove("d-none");
          document.getElementById('deleteImage' + imageName).classList.add("isvisible", "text-success");
          document.getElementById('deleteImage' + imageName).style.cursor = "initial";
          document.getElementById('spinner' + imageName).classList.add("d-none");
          document.getElementById('deleteImage' + imageName).setAttribute("name", "checkmark-done-circle-outline");
          document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-success alert-dismissible fade show popup-notification" role="alert">' + response.message + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
          setTimeout(reload, 1000)
        }

        function reload() {
          location.reload();
        }
      } else {
        document.getElementById('deleteImage' + imageName).classList.add("d-none");
        document.getElementById('deleteImage' + imageName).style.pointerEvents = "none";
        document.getElementById('openImage' + imageName).classList.add("d-none");
        document.getElementById('spinner' + imageName).classList.remove("d-none");
        document.getElementById('overlay' + imageName).classList.add("overlay-inprogress");
        setTimeout(startAnimation, 1000)

        function startAnimation() {
          document.getElementById('deleteImage' + imageName).classList.remove("d-none");
          document.getElementById('deleteImage' + imageName).classList.add("isvisible", "text-danger");
          document.getElementById('deleteImage' + imageName).style.cursor = "initial";
          document.getElementById('spinner' + imageName).classList.add("d-none");
          document.getElementById('deleteImage' + imageName).setAttribute("name", "bug-outline");
          document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-danger alert-dismissible fade show popup-notification" role="alert">' + response.code + ' - ' + response.message + '<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
          setTimeout(endAnimation, 1500)
        }

        function endAnimation() {
          document.getElementById('openImage' + imageName).classList.remove("d-none");
          document.getElementById('deleteImage' + imageName).classList.remove("isvisible", "text-danger");
          document.getElementById('deleteImage' + imageName).removeAttribute('style');
          document.getElementById('overlay' + imageName).classList.remove("overlay-inprogress");
          document.getElementById('deleteImage' + imageName).setAttribute("name", "trash-outline");
        }
      }
    };

    // En cas d'erreur au moment du XHR.Open
    xhr.onerror = function () {
      document.getElementById('deleteImage' + imageName).classList.add("d-none");
      document.getElementById('deleteImage' + imageName).style.pointerEvents = "none";
      document.getElementById('openImage' + imageName).classList.add("d-none");
      document.getElementById('spinner' + imageName).classList.remove("d-none");
      document.getElementById('overlay' + imageName).classList.add("overlay-inprogress");
      document.getElementById('popup').innerHTML = '<div class="popup"> <div class="alert alert-danger alert-dismissible fade show popup-notification" role="alert">Impossible de joindre Guideweb, merci de contacter un administrateur<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button> </div></div>'
      setTimeout(startAnimation, 1000)

      function startAnimation() {
        document.getElementById('deleteImage' + imageName).classList.remove("d-none");
        document.getElementById('deleteImage' + imageName).classList.add("isvisible", "text-danger");
        document.getElementById('deleteImage' + imageName).style.cursor = "initial";
        document.getElementById('spinner' + imageName).classList.add("d-none");
        document.getElementById('deleteImage' + imageName).setAttribute("name", "bug-outline");
        setTimeout(endAnimation, 1500)
      }

      function endAnimation() {
        document.getElementById('openImage' + imageName).classList.remove("d-none");
        document.getElementById('deleteImage' + imageName).classList.remove("isvisible", "text-danger");
        document.getElementById('deleteImage' + imageName).removeAttribute('style');
        document.getElementById('overlay' + imageName).classList.remove("overlay-inprogress");
        document.getElementById('deleteImage' + imageName).setAttribute("name", "trash-outline");
      }
    }

    // Send the data.
    xhr.send(formData);
    var formData = new FormData();
  }
}


